.card8 {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px; /* Rounded corners */
    max-width: 350px;
    margin: auto;
    text-align: center;
    font-family: Arial, sans-serif;
    background-color: #fff; /* White background for better contrast */
    padding: 20px; /* Padding around the content */
}

.card8 img {
    border-radius: 50%; /* Circular profile image */
    width: 120px;
    height: 300px;
    object-fit: cover; /* Ensures the image covers the area */
   
}

.card8 h1 {
    font-size: 24px;
    margin: 10px 0;
    color: #333; /* Darker color for better readability */
}

.card8 p {
    color: #555; /* Medium grey for text */
    margin: 10px 0;
}

.social-links {
    margin: 20px 0;
    display: flex;
    justify-content: center; /* Center align items horizontally */
}

.social-links a {
    text-decoration: none;
    font-size: 24px;
    color: #333; /* Darker color for icons */
    margin: 0 10px; /* Space between icons */
    transition: color 0.3s ease; /* Smooth color transition */
}

.social-links a:hover {
    color: #007bff; /* Change color on hover */
}

.btn-open-modal {
    border: none;
    outline: 0;
    display: inline-block;
    padding: 10px;
    color: white;
    background-color: #007bff; /* Blue background for button */
    text-align: center;
    cursor: pointer;
    width: 100%;
    font-size: 18px;
    border-radius: 5px; /* Rounded button corners */
    transition: background-color 0.3s ease; /* Smooth background color transition */
}

.btn-open-modal:hover {
    background-color: #0056b3; /* Darker blue on hover */
}

/* Modal styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    max-width: 400px;
    position: relative;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: transparent;
    font-size: 24px;
    cursor: pointer;
}

.modal-title {
    font-size: 24px;
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 15px;
}

.input-field {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
}

.btn-blue {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-blue:hover {
    background-color: #0056b3;
}
